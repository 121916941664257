import { getContentfulData, QueryClient } from '@smu-chile/pkg-unimarc-hooks'
import { IOptiornsEntriesRequest } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IContentful'

export interface OptionsProps{
  options: IOptiornsEntriesRequest
}

export interface ContentComponents {
  contentOptions: OptionsProps
}

export const getContentFulComponents = async ({ contentOptions }: ContentComponents) => {
  const queryClient = new QueryClient()
  await queryClient.prefetchQuery(
    `contenful_${contentOptions['content_type']}`,
    () => {
      return getContentfulData(contentOptions)
    })
  const data = queryClient.getQueryData(`contenful_${contentOptions['content_type']}`)

  const includes = data?.['includes']

  const getIncludeItems = ({ query, type = 'Entry' }) => {
    return includes[type]?.find?.((elem) => {
      return elem?.sys?.id == query
    }) ?? null
  }

  const getNextLvl = (level0) => {
    if (!level0) return null

    const search = Object?.entries(level0).map((validateLvl) => {
      if (typeof level0[validateLvl[0]] == 'object') {
        return validateLvl[0] ?? null
      }

      return false
    })

    return search.filter((next) => { return next })
  }

  const response = {}

  data?.['items'][0]?.fields?.components?.forEach?.((level0) => {
    const Datalevel1 = getIncludeItems({ query: level0?.sys?.id }) ?? null
    const level1 = Datalevel1?.fields || {}
    const mapNextLvl = getNextLvl(level1)

    mapNextLvl?.forEach?.((lvl1: string) => {
      const tempLvl2 = level1[lvl1]?.map?.((level2) => {

        const level2Data = getIncludeItems({ query: level2?.sys?.id })?.fields ?? null

        const mapNextLvlSource = getNextLvl(level2Data) ?? null

        mapNextLvlSource?.forEach?.((lvl2: string) => {
          level2Data[lvl2] = getIncludeItems({
            query: level2Data[lvl2]?.sys?.id,
            type: 'Asset'
          }) ?? null
        })

        return level2Data ?? null
      })

      level1[lvl1] = tempLvl2?.filter(lvlData => { return lvlData }) ?? null
    })

    const nameObject = `${Datalevel1?.sys?.contentType?.sys?.id}_${Datalevel1?.sys?.id}`
    response[nameObject] = level1 ?? null
  })

  return { response, queryClient }
}