import { useState } from 'react'
import { Carousel, Picture, Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  addToHomeData,
  clickedPromos,
  useMobile,
  visiblePromos
} from '@smu-chile/pkg-unimarc-hooks'
import { BigScreen } from '@smu-chile/pkg-unimarc-components/helpers'

export const ShowCaseWeb = ({
  data,
  cfIndex,
  site,
  userId,
  orderForm
}:{
    data: object[],
    cfIndex?: number,
    site?: string,
    userId?: string,
    orderForm?: object
 }) => {

  const { isMobile } = useMobile()
  const [autoPlay, setAutoPlay] = useState(true)
  const contentObject = isMobile ? 'imageMobile' : 'imageDesktop'
  const containerProps = { maxWidth: '1120px', minHeight: '450px' }
  const handleRedirect = (promosData) => { 
    clickedPromos(promosData)
  }

  const handleStart = () => {
    return setAutoPlay(false)
  }

  const handleEnd = () => {
    return setAutoPlay(true)
  }

  return (
    <>
      <Spacer.Horizontal size={12} />
      <Carousel
        afterChange={()=>{ visiblePromos({site: site, userId: userId}) }}
        arrows={!isMobile}
        autoPlay={autoPlay}
        containerProps={containerProps}
        deviceType={isMobile ? 'mobile' : 'desktop'}
        focusOnSelect
        infinite
        opacityArrows
        pauseOnHover
        showDots
        ssr
      >
        {data?.map((item, index) => {
          if (!item) return null
          const promoData = { ...item, site, userId, orderForm }
          const width = item[contentObject]?.fields?.file?.details?.image?.width
          const height = item[contentObject]?.fields?.file?.details?.image?.height

          return (
            <Picture
              anchor
              catchImgRef={(ref) => {addToHomeData({ref, promoData: {...item, locationId: `${cfIndex} - ${index + 1}`}})}}
              height={height}
              key={item['label']}
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                event.stopPropagation()
                handleRedirect(promoData)
              }}
              onTouchEnd={handleEnd}
              onTouchStart={handleStart}
              src={item[contentObject]?.fields?.file?.url}
              style={{
                cursor: 'pointer',
                height: '100%',
                width: '100%'
              }}
              title={item[contentObject]?.fields?.title}
              url={item['reference']}
              width={width}
            />
          )
        })}
      </Carousel>
      <BigScreen>
        <Spacer.Horizontal size={32} />
      </BigScreen>
    </>
  )
}
