import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'

export enum AlviLevels {
  clubalvi = 'Socio Club',
  plata = 'Socio Plata',
  oro = 'Socio Oro',
  vip = 'Socio Vip',
}

export enum AlviButtons {
  clubalvi = 'club',
  plata = 'plata',
  oro = 'oro',
  vip = 'vip',
}

const AlviLevelsOrder = ['clubalvi', 'plata', 'oro', 'vip']

export const alviLevelsCF = (data, userLevel, setShowModal, isMobile = false) => {
  const infoBlock = isMobile ? 'infoBlocks' : 'infoBlocksDesktop'
  const headerDataRaw = data?.[0]?.[infoBlock]?.[0]?.items?.find((level) => {
    return level?.description.toLowerCase().includes(AlviLevels[userLevel || 'clubalvi']?.toLowerCase())
  })
  const headerData = {
    level: headerDataRaw?.fields?.description,
    fontColor: headerDataRaw?.fields?.fontColor,
    backgroundColor: headerDataRaw?.fields?.backgroundColor,
    icon: headerDataRaw?.fields?.items?.[0]?.image?.fields?.file?.url,
    goalImage: headerDataRaw?.fields?.items?.[2]?.image?.fields?.file?.url,
    progressBar: headerDataRaw?.fields?.items?.[1]?.image?.fields?.file?.url
  }

  const buttonLevelIndex = Object.keys(AlviButtons).findIndex(level => { return level.includes(userLevel) })
  const buttonNextLevel = Object.values(AlviButtons)[buttonLevelIndex + 1] ?? AlviButtons.vip

  const buttonsData = data?.[0]?.[infoBlock]?.[1]?.fields?.items?.filter((buttonEntry) => {
    return buttonEntry?.alviEnabledLevels.includes(AlviButtons[userLevel])
  }).map((buttonEntry) => {
    return {
      text: buttonEntry?.label,
      url: buttonEntry?.action,
      icon: buttonEntry?.icon?.fields?.file?.url,
      onClick: () => {
        if (buttonEntry?.label?.toLowerCase().includes('beneficios')) {
          setShowModal(true)
        } else {
          window.open(buttonEntry?.fields?.navigateTo,'_self')
        }
      }
    }
  })

  const benefitsImageRaw = data?.[0]?.[infoBlock]?.[2]?.fields?.items.filter((imageEntry) => {
    return imageEntry?.label.includes(AlviButtons[userLevel])
  })

  const benefitsImagesBlock = data?.[0]?.[infoBlock]?.[2]
  const [nextBenefitsImage] = benefitsImagesBlock?.fields?.items.filter((image) => {
    return image?.fields?.label?.includes(buttonNextLevel)
  })

  return {
    headerData,
    buttonsData,
    benefitsImage: benefitsImageRaw?.[0]?.image?.fields?.file?.url,
    nextBenefitsImage: nextBenefitsImage?.fields?.image?.fields?.file?.url
  }
}

export const isClubOrVip = (userLevel) => {
  if (userLevel?.toLowerCase().includes('club') || userLevel?.toLowerCase().includes('vip')) {
    return true
  }
  return false
}

export const showButtons = (isMobile, userLevel) => {
  if (!isMobile && isClubOrVip(userLevel)) return false
  return true
}

export const filterClubBlockLoggedIn = (blocks, userLevel) => {
  const filteredBlock = isValidArrayWithData(blocks) && blocks.filter((block) => {
    return block?.alviEnabledLevels.includes(AlviButtons[userLevel])
  })

  return filteredBlock && filteredBlock?.map((blockEntry) => {
    if (blockEntry?.imageBanner) {
      return {
        ...blockEntry,
        imageBanner: blockEntry?.imageBanner.filter((image) => {
          return image?.alviEnabledLevels?.includes(AlviButtons[userLevel])
        })
      }
    }
    return blockEntry
  })
}

export const setNextMembershipCycle = () => {
  const date = new Date()
  const day = date.getDate()
  const monthNextYear = date.getMonth() + 1 === 12 ? 1 : date.getMonth() + 2
  const monthRaw = day < 5 ? date.getMonth() + 1 : monthNextYear
  const month = monthRaw < 10 ? `0${monthRaw}` : monthRaw
  const year =
    day >= 5 && date.getMonth() + 1 === 12
      ? date.getFullYear() + 1
      : date.getFullYear()

  return `05/${month}/${year}`
}

export const transformAlliances = (alliances, userLevel, isMobile) => {
  return alliances?.filter((alliance) => {
    const isForUser = alliance.showLevel.includes(userLevel)
    return isForUser
  }).sort((a, b) => {
    if (a?.bannerRanking > b?.bannerRanking) return 1
    if (a?.bannerRanking < b?.bannerRanking) return -1
    return 0
  }).map((alliance) => {
    const handleClickActivation = (router) => {
      if (alliance?.referenceType === 'Redirection') router.push(alliance?.reference)
    }
    return {
      imageBanner: alliance?.[isMobile ? 'bannerImage' : 'bannerImageDesktop']?.fields?.file?.url,
      title: alliance?.titulo,
      mainTitleActive: alliance?.mainTitleActive,
      mainTitleInactive: alliance?.mainTitleInactive,
      textActive: alliance?.textActive,
      textInactive: alliance?.textInactive,
      onClickActivation: (router) => { handleClickActivation(router) },
      key: alliance?.sys?.id,
      activationLevel: alliance?.activationLevel,
      refId: alliance?.refId,
      modalImage: alliance?.modalImage?.fields?.file?.url,
      modalTitle: alliance?.modalTitle,
      titleInactive: alliance?.mainTitleActive,
      titleActive: alliance?.mainTitleInactive,
      reference: alliance?.reference,
      referenceType: alliance?.referenceType,
      legal: alliance?.legales
    }
  })
}

export const checkLowestLevel = (alliance, badgeEntry) => {
  const activationLevelSorted = alliance?.activationLevel.sort((a, b) => {
    if (AlviLevelsOrder.indexOf(a) > AlviLevelsOrder.indexOf(b)) return 1
    if (AlviLevelsOrder.indexOf(a) < AlviLevelsOrder.indexOf(b)) return -1
    return 0
  })
  return badgeEntry?.fields?.label?.toLowerCase().includes(AlviButtons[activationLevelSorted[0]])
}