import {
  useEffect,
  useRef,
  useState
} from 'react'
import { useRouter } from 'next/router'
import {
  Container,
  createNotification,
  Header,
  Overlay,
  SearchProps
} from '@smu-chile/pkg-unimarc-components'
import { BigScreen } from '@smu-chile/pkg-unimarc-components/helpers/mediaQueries'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { StoreLocatorHeaderProps } from '@smu-chile/pkg-unimarc-components/stories/organisms/Header'
import {
  AddressTypeProp,
  checkUserLoginFlow,
  Cookies,
  currencyFormat,
  eventClick,
  getContentfulImageCategories,
  postAlviLogout,
  simulateCart,
  sleep,
  tagClickCouponIconInHeader,
  useAlviSession,
  useCart,
  useCategories,
  useContentful,
  useEvents,
  useMobile,
  useSearch,
  useTrigerEvent,
  useUpdateFromShelfAlvi,
  useUser
} from '@smu-chile/pkg-unimarc-hooks'
import { IUser } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IUser'
import { ForgotPasswordModalBrowse } from 'components/ForgotPasswordModalBrowse'
import { LoginModalBrowse } from 'components/LoginModalBrowse'
import { formatDate } from 'shared/utils/formatDate'
import {
  aislesProps,
  IResult,
  searchProps,
  useAccountTooltip
} from './helpers'
import { variableBreakdownCF } from './helpers/variableBreakdownCF'
import { myProfileBannerAssetIdByShabitName } from './helpers/myProfileDropdown'
import { CATEGORIES_APP_CONTENTFUL, CONTENTFUL_LOGIN_IMAGE, CONTENTFUL_OFFERS_LINK } from 'shared/utils/constanst'

const customProperties = (user: IUser, isMobile: boolean) => {
  const validationStatus: string = user?.validationStatus ?? 'logout'
  const positionHeaderBrowse: typeof Container.arguments.position = isMobile ? 'sticky' : 'initial'

  return { validationStatus, positionHeaderBrowse }
}

export const HeaderBrowse = (): React.ReactElement => {
  // use
  const { isLoggedIn } = useAlviSession()
  const user = useUser()
  const userId = user?.userId
  const router = useRouter()
  const { trigger } = useTrigerEvent()
  const { isMobile } = useMobile()
  const { result, handleChangeSearch } = useSearch('alvi')
  const { cfDataHeader } = variableBreakdownCF()
  const categoriesApp = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CATEGORIES_TYPE,
      'sys.id[in]': CATEGORIES_APP_CONTENTFUL
    }
  })
  const imgOfOffers = useContentful({
    id_contentful: 'offers_img',
    options: {
      'fields.title[in]': 'Ofertas mobile'
    },
    reactQuery: {
      enabled: isMobile
    },
    type: 'assets'
  })
  const offersLink = useContentful({
    id_contentful: 'offersLink',
    options: {
      'sys.contentType.sys.id[in]': 'home',
      'sys.id[in]': CONTENTFUL_OFFERS_LINK
    }
  })
  const {
    data,
    isFetched,
    isLoading
  } = useCart({
    reactQuery: {
      enabled: isLoggedIn
    }
  })

  const addressType = data?.address?.addressType as AddressTypeProp
  const resultAisles = useCategories({ site: 'alvi' })
  const inputAdornmentRef = useRef(null)

  const {
    message: accountTooltipMessage,
    title: accountTooltipTitle
  } = useAccountTooltip({
    type: router.query['fromContent'] as string
  })

  // is
  const [name, setName] = useState('Mi perfil')
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false)
  const [isAccountTooltipOpen, setIsAccountTooltipOpen] = useState(true)
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const [isVisableBanner, setIsVisableBanner] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [isVisibleAisles, setIsVisibleAisles] = useState(false)
  const [isVisibleClearIcon, setIsVisibleClearIcon] = useState(false)
  const [isVisibleModalLogout, setIsVisibleModalLogout] = useState(false)
  const [isVisibleMyProfile, setIsVisibleMyProfile] = useState(false)
  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const [redirectTo, setRedirectTo] = useState('')
  const [sla, setSla] = useState('')
  const [value, setValue] = useState('')

  // primitive
  const site = 'alvi'
  const shouldLoginModalBeOpen = router.query.login === 'true'
  const shouldForgotPasswordModalBeOpen = router.query['forgot-password'] === 'true'
  const selectedAddresses = data?.address
  const isPickup = selectedAddresses?.addressType == 'pickup-in-point'
  const addressDelivery = selectedAddresses?.street ? `${selectedAddresses?.street}, ${selectedAddresses?.number}` : ''
  const addressSelected = isPickup ? `Alvi ${selectedAddresses?.neighborhood}` || '' : addressDelivery
  const iconStoreLocatorLogin = isPickup ? 'Shop' : 'Location'
  const iconStoreLocator: StoreLocatorHeaderProps['iconStoreLocator'] = isLoggedIn && selectedAddresses ? iconStoreLocatorLogin : 'Location'
  const linkOffers = offersLink.data?.['includes']?.['Entry']?.[0]?.fields?.text || process.env.NEXT_PUBLIC_OFFERSURL
  const showCouponTooltip = process.env.NEXT_PUBLIC_SHOW_COUPON_TOOLTIP === 'true'
  const offersMobile = {
    url: linkOffers,
    img: imgOfOffers.data?.['items'][0]?.fields?.file?.url
  }

  const { validationStatus } = customProperties(user, isMobile)

  // get
  const categoriesDataImage = getContentfulImageCategories(categoriesApp?.data)
  const { editTempOrderForm, ...useUpdateProps } = useUpdateFromShelfAlvi({
    fromWhere: 'search',
    isOrderFormLoading,
    isSearch: true,
    products: result?.intelligenceSearch?.data?.pages[0]?.availableProducts,
    userId
  })
  const smallScreenSLVisible = typeof window !== 'undefined' && !['/live-shopping-unimarc'].includes(window?.location?.pathname)
  const searchPropsData = searchProps({
    ...useUpdateProps,
    inputAdornmentRef,
    isMobile,
    isVisible,
    isVisibleClearIcon,
    result: result as unknown as IResult,
    value,
    userId,
    handleChangeSearch,
    setIsVisible,
    setIsVisibleClearIcon,
    setValue
  })
  const aislesPropsData = aislesProps({
    categoriesDataImage,
    isMobile,
    isVisableBanner,
    isVisibleAisles,
    offersMobile,
    resultAisles,
    setIsVisableBanner,
    setIsVisibleAisles
  })
  const isQuantityLoading = (isOrderFormLoading || Boolean(useUpdateProps.saveDataState?.length))

  const getAccountTooltipOpen = () => {
    const isFromContentLoginDocument = router.query['fromContent'] === 'loginDocument'
    const isFromContentRegister = router.query['fromContent'] === 'register'

    const isFromContent = (
      isFromContentLoginDocument ||
      isFromContentRegister
    )

    return (
      isAccountTooltipOpen &&
      isFromContent &&
      isLoggedIn
    )
  }

  const redirect = (url: string) => {
    window.location.href = url
  }

  const handleClose = () => {
    if (isVisible) setIsVisible(false)
    if (isVisibleAisles) setIsVisibleAisles(!isVisibleAisles)
    if (isVisibleMyProfile) setIsVisibleMyProfile(false)
  }
  useEffect(() => {
    if (isVisibleAisles) window.addEventListener('keyup', e => { return e.code === 'Escape' && setIsVisibleAisles(false) })
    return () => { return window.removeEventListener('keyup', () => { return {} }) }
  }, [isVisibleAisles])

  const handleOpenLoginModal = () => {
    setIsVisibleAisles(false)
    setLoginModalOpen(true)
    router.push({
      query: {
        ...router.query,
        login: true,
        ['forgot-password']: false,
        step: undefined
      }
    }, undefined, {
      shallow: true
    })
  }

  const handleCloseLoginModal = async () => {
    setLoginModalOpen(false)
    trigger({ eventType: 'storeLocator', data: { show: false } })
    await router.push({
      query: {
        ...router.query,
        login: undefined,
        ['forgot-password']: false,
        step: undefined
      }
    }, undefined, {
      shallow: true
    })
  }

  const handleOpenForgotPasswordModal = () => {
    setForgotPasswordModalOpen(true)
    setLoginModalOpen(false)
    router.push({
      query: {
        ...router.query,
        login: undefined,
        ['forgot-password']: true
      }
    }, undefined, {
      shallow: true
    })
  }

  const handleCloseForgotPasswordModal = () => {
    setForgotPasswordModalOpen(false)
    router.push({
      query: {
        ...router.query,
        login: undefined,
        ['forgot-password']: undefined,
        ['step']: undefined
      }
    }, undefined, {
      shallow: true
    })
  }

  const handleLogout = async () => {
    Cookies.remove('addToCart')
    const res = await postAlviLogout()
    if (res.status === 200) {
      await router.push('/')
      router.reload()
    } else {
      createNotification({
        message: 'Error al cerrar sesión, por favor intente nuevamente',
        align: 'top-right',
        site: 'alvi',
        closeTimeout: 2000
      })
    }
  }

  const handleStoreLocator = () => {
    if (!isLoggedIn) {
      handleOpenLoginModal()
      return
    }
    setIsVisibleAisles(false)
    eventClick({ eventCategory: 'interacciones en carro de compras', 'event-label': 'selector de direccion en header' })
    trigger({ eventType: 'storeLocator', data: { show: true } })
  }

  // validate if should redirect when finsih loading orderForm
  useEffect(() => {
    if (!isOrderFormLoading && redirectTo != '' && useUpdateProps?.saveDataState.length == 0) {
      redirect(redirectTo)
    }
  }, [
    isOrderFormLoading,
    redirectTo,
    useUpdateProps?.saveDataState
  ])

  // validate if should redirect when finsih loading orderForm
  useEffect(() => {
    if (!isOrderFormLoading && redirectTo != '' && useUpdateProps?.saveDataState.length == 0) {
      redirect(redirectTo)
    }
  }, [
    isOrderFormLoading,
    redirectTo,
    useUpdateProps?.saveDataState
  ])

  const handleClickCart = () => {
    const validation = checkUserLoginFlow(isLoggedIn, data?.address, true, site)
    const hasQuantity = useUpdateProps?.saveDataState.length == 0

    if (validation && hasQuantity && !isOrderFormLoading) router.push(process.env.NEXT_PUBLIC_CARTURL)
    if (!hasQuantity) {
      setRedirectTo(process.env.NEXT_PUBLIC_CARTURL)
      trigger({
        eventType: 'addToCart', data: {
          loading: true,
          items: useUpdateProps?.saveDataState
        }
      })
    }
  }

  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm', callBack: ({ detail: { loading, items } }) => {
      editTempOrderForm(items, loading)
      setIsOrderFormLoading(loading)
    }
  })

  useEvents({
    eventType: 'loginModal', callBack: ({ detail: { show } }) => {
      if (show) handleOpenLoginModal()
      else handleCloseLoginModal()
    }
  })

  useEvents({
    eventType: 'forgotPassword', callBack: ({ detail: { show } }) => {
      if (show) handleOpenForgotPasswordModal()
      else handleCloseForgotPasswordModal()
    }
  })

  useEffect(() => {
    const params = router.query?.['q']

    if (params && typeof params === 'string') {
      setValue(params)
    }
  }, [])

  // validate if should redirect when finsih loading orderForm
  useEffect(() => {
    if (!isOrderFormLoading && redirectTo != '' && useUpdateProps?.saveDataState.length == 0) {
      router.push(redirectTo)
    }
  }, [
    isOrderFormLoading,
    redirectTo,
    useUpdateProps?.saveDataState
  ])

  useEffect(() => {
    setLoginModalOpen(shouldLoginModalBeOpen)
  }, [shouldLoginModalBeOpen])

  useEffect(() => {
    setForgotPasswordModalOpen(shouldForgotPasswordModalBeOpen)
  }, [shouldForgotPasswordModalBeOpen])

  useEffect(() => {
    (async () => {
      const existAddressInOrderForm = typeof data?.address === 'undefined' && !isLoading
      const notExistAddress = !selectedAddresses || selectedAddresses?.['country'] == ''
      if (notExistAddress && isFetched && existAddressInOrderForm) {
        await sleep(1000)
        trigger({ eventType: 'storeLocator', data: { show: true } })
        setSla('')
      }
      const response = await simulateCart({
        country: selectedAddresses?.['country'],
        geoCoordinates: selectedAddresses?.['geoCoordinates'],
        items: [{ quantity: '1', seller: '1', id: process.env.NEXT_PUBLIC_PRODUCT_DUMMY || '' }],
        addressType
      })
      const slaValue = response?.slas?.find(logisticsInfo => { return logisticsInfo.availableDeliveryWindows.length > 0 })?.availableDeliveryWindows[0]
      if (data?.address && !slaValue) setSla('Sin cobertura')
      if (response?.nextWindow) {
        const { lisPrice, startDateUtc } = response.nextWindow
        const {
          day,
          month,
          hourMinutes24,
          shortDayName
        } = formatDate({ date: startDateUtc })
        const slaPrice = isPickup ? 'Gratis' : `${currencyFormat({ number: lisPrice })}`
        setSla(`Desde: ${shortDayName.charAt(0).toUpperCase() + shortDayName.slice(1)} ${day}/${month}, ${hourMinutes24} hrs - ${slaPrice}`)
      }
    })()
  }, [selectedAddresses, isFetched, isLoading])

  useEffect(() => {
    if (
      !isLoading &&
      user?.firstName ||
      data?.firstName
    ) {
      const userNickName = user?.firstName
      const dataNickName = data?.firstName
      if (!userNickName && !dataNickName) {
        return setName('Mi perfil')
      }
      if (userNickName && !dataNickName) {
        return setName(dataNickName)
      }
      if (!userNickName && dataNickName) {
        return setName(userNickName)
      }
      if (userNickName && dataNickName) {
        return setName(dataNickName)
      }
      return setName('Mi perfil')
    }
    return setName('Mi perfil')
  }, [
    isLoading,
    isFetched,
    user?.firstName,
    data?.firstName
  ])

  const loginImageData = useContentful({
    id_contentful: 'login_modal',
    options: {
      'sys.id': CONTENTFUL_LOGIN_IMAGE 
    },
    type: 'assets'
  })

  const loginImageUrl = loginImageData.data?.['items']?.[0]?.fields.file.url

  const myProfileBannerImageData = useContentful({
    id_contentful: 'my_profile_banner',
    addOptionsToCacheKey: true,
    options: {
      'sys.id': myProfileBannerAssetIdByShabitName[user?.shabits]
    },
    type: 'assets'
  })

  const myProfileBannerImageUrl = myProfileBannerImageData.data?.['items'].length === 1 ? myProfileBannerImageData.data?.['items']?.[0].fields.file.url : null

  const handleAccountTooltipClose = () => {
    setIsAccountTooltipOpen(false)
  }

  const handleClickCoupon = () => {
    tagClickCouponIconInHeader()
    router.push(process.env.NEXT_PUBLIC_COUPONSURL)
  }

  const handleClickGoToMyCoupons = async () => {
    await router.push(process.env.NEXT_PUBLIC_COUPONSURL)
    setIsVisibleMyProfile(false)
  }

  const handleUpdateDocumentSuccess = async () => {
    await router.push('/?fromContent=loginDocument')
  }

  const handleUpdatePasswordSuccess = async () => {
    await router.push('/')
    router.reload()
  }

  const shouldAccountTooltipBeOpen = getAccountTooltipOpen()

  useEffect(() => {
    const listener = (e) => {
      if (isMobile) return
      const menuContainerId = 'header__aisle_menu_button'
      const isAisleMenu = e.target?.parentElement?.parentNode?.id === menuContainerId
      if (!isAisleMenu) setIsVisibleAisles(false)
    }

    window.addEventListener('click', listener)

    return () => {
      window.removeEventListener('click', listener)
    }
  }, [isMobile])

  return (
    <>
      <LoginModalBrowse
        imageSrc={loginImageUrl}
        isOpen={loginModalOpen}
        onClose={handleCloseLoginModal}
        onForgotPasswordClick={handleOpenForgotPasswordModal}
        onUpdateDocumentSuccess={handleUpdateDocumentSuccess}
        onUpdatePasswordSuccess={handleUpdatePasswordSuccess}
      />
      <ForgotPasswordModalBrowse
        imageSrc={loginImageUrl}
        isOpen={forgotPasswordModalOpen}
        onBack={handleOpenLoginModal}
        onClose={handleCloseForgotPasswordModal}
      />

      <Container
        id='header__browse'
        isWrap
        position='sticky'
        zIndex={getGlobalStyle('--z-index-10')}
      >
        <Header
          accountTooltipProps={{
            onClose: handleAccountTooltipClose,
            primaryText: accountTooltipTitle,
            secondaryText: accountTooltipMessage
          }}
          addressSelected={addressSelected || 'Selecciona tu dirección'}
          aislesProps={{
            ...aislesPropsData,
            iconAisles: 'Hamburger',
            statusAisles: isVisibleAisles ? 'open' : 'close',
            title: 'Categorías',
            titleMobile: 'Nuestras categorías',
            isVisibleAislesIcon: false,
            iconSizeCategoriesMobile: 24,
            isVisibleAislesIconBurger: true
          }}
          background={getGlobalStyle('--color-alvi-primary-blue-gradient')}
          cfDataHeader={cfDataHeader}
          colorQuantity={'blue'}
          customBannerUrl={myProfileBannerImageUrl}
          iconStoreLocator={iconStoreLocator}
          isAccountTooltipOpen={shouldAccountTooltipBeOpen}
          isLoggedIn={isLoggedIn}
          isMobile={isMobile}
          isVisibleCoupon={true}
          isVisibleModalLogout={isVisibleModalLogout}
          isVisibleMyProfile={isVisibleMyProfile}
          linkOffers={linkOffers}
          logo={'LogoHeaderAlvi'}
          onClickCart={handleClickCart}
          onClickCloseLogoutMobile={() => { setIsVisibleModalLogout(false) }}
          onClickCloseMyProfile={() => { setIsVisibleMyProfile(false) }}
          onClickCoupon={handleClickCoupon}
          onClickGoToMyCoupons={handleClickGoToMyCoupons}
          onClickGoToMyOrders={() => { router.push(process.env.NEXT_PUBLIC_MYORDERSURL) }}
          onClickGoToMyRequest={() => {
            // pass
            return
          }}
          onClickLogin={handleOpenLoginModal}
          onClickLogout={handleLogout}
          onClickLogoutMobile={() => { setIsVisibleModalLogout(true) }}
          onClickMyProfile={() => {
            setIsVisibleAisles(false)
            setIsVisibleMyProfile(!isVisibleMyProfile)
          }}
          onClickMyProfileBanner={() => {
            if (window.location.origin === process.env.NEXT_PUBLIC_MYORDERSURL) window.location.reload()
            router.push(`${process.env.NEXT_PUBLIC_MYORDERSURL}/benefits`)
          }}
          onClickStoreLocator={handleStoreLocator}
          quantity={useUpdateProps.totalQuantity}
          quantityLoading={isQuantityLoading}
          searchProps={{
            backgroundGradientIconSearch: getGlobalStyle('--color-alvi-primary-blue-gradient'),
            iconArrowSearch: 'ArrowBackNavigate',
            ...searchPropsData,
            onChangeSearch: (e) => {
              searchPropsData.onChangeSearch(e)
              setIsVisibleAisles(false)
            }
          } as SearchProps}
          showCouponTooltip={showCouponTooltip}
          site='alvi'
          sizeLogo={58}
          sizeLogoMobile={38}
          smallScreenSLVisible={smallScreenSLVisible}
          timeTable={sla}
          urlLogo={process.env.NEXT_PUBLIC_HOMEURL}
          userFirstName={
            data?.firstName ||
            user?.firstName ||
            'Mi perfil'
          }
          userShabitName={user?.shabits || name}
          validationStatus={validationStatus}
        />

        <BigScreen>
          {(isVisible || isVisibleAisles || isVisibleMyProfile) && <Overlay onClick={handleClose} />}
        </BigScreen>
      </Container>
    </>
  )
}

