import { IEntriesProps, useContentful } from '@smu-chile/pkg-unimarc-hooks'
import { CONNECTION_CONTENTFUL_HEADER_MENU } from '../constanst'

interface IConnectionCfHeaderMenu {
  dataHeaderCF?:IEntriesProps;
}

const ConnectionCfHeaderMenu = (): IConnectionCfHeaderMenu => {
  const {
    data: dataHeaderCF
  } = useContentful({
    id_contentful: `CentroDeAyuda-HeaderMenuAlvi`,
    options: {
      'sys.contentType.sys.id[in]': 'viewBlock',
      'sys.id[in]': CONNECTION_CONTENTFUL_HEADER_MENU 
    },
    reactQuery: {
      enabled: true,
      retry: 3, staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false
    }
  })

  return {
    dataHeaderCF
  }
}

export { ConnectionCfHeaderMenu }
