import { Icons } from '@smu-chile/pkg-unimarc-components/assets/SvgComponents/SvgIcons'
import { ConnectionCfHeaderMenu } from '../../../shared/utils/connectionCfHeader'

export interface IVariableBreakdownCF {
  cfDataHeader?: object;
}

export const variableBreakdownCF = (): IVariableBreakdownCF => {
  const { dataHeaderCF } = ConnectionCfHeaderMenu()

  const buttonMyShopping = dataHeaderCF?.includes?.Entry?.find((data) => { return data?.sys?.id === '2dRNAFbX7paX5WKQuwrHrc'.toString() })
  const buttonSingOff = dataHeaderCF?.includes?.Entry?.find((data) => { return data?.sys?.id === '20UF9x6PQ0r8AZJ8QGbxmg'.toString() })
  const buttonCoupon = dataHeaderCF?.includes?.Entry?.find((data) => { return data?.sys?.id === '7ABClQ6PC0wphf9u5RwzmP'.toString() })

  const iconMyShopping = buttonMyShopping?.fields?.iconText as keyof typeof Icons
  const iconSingOff = buttonSingOff?.fields?.iconText as keyof typeof Icons
  const iconCoupon = buttonCoupon?.fields?.iconText as keyof typeof Icons
  const labelMyShopping = buttonMyShopping?.fields?.label
  const labelSingOff = buttonSingOff?.fields?.label
  const labelCoupon = buttonCoupon?.fields?.label
  const showBtnMyShopping = buttonMyShopping?.fields?.showbuttom
  const showBtnSingOff = buttonSingOff?.fields?.showbuttom
  const showBtnCoupon = buttonCoupon?.fields?.showbuttom
  const showLabelMyShopping = buttonMyShopping?.fields?.showLabel
  const showLabelCoupon = buttonCoupon?.fields?.showLabel
  const showLabelSingOff = buttonSingOff?.fields?.showLabel
  const textLabelMyShopping = buttonMyShopping?.fields?.labelText
  const textLabelSingOff = buttonSingOff?.fields?.labelText
  const textLabelCoupon = buttonCoupon?.fields?.labelText
  const colorLabelMyShopping = buttonMyShopping?.fields?.labelColorText
  const colorLabelSingOff = buttonSingOff?.fields?.labelColorText
  const colorLabelCoupon = buttonCoupon?.fields?.labelColorText
  const backraundLabelMyShopping = buttonMyShopping?.fields?.labelColorBackground
  const backraundLabelSingOff = buttonSingOff?.fields?.labelColorBackground
  const backraundLabelCoupon = buttonCoupon?.fields?.labelColorBackground
    
  const cfDataHeader = {
    backraundLabelCoupon,
    backraundLabelMyShopping,
    backraundLabelSingOff,
    colorLabelCoupon,
    colorLabelMyShopping,
    colorLabelSingOff,
    iconCoupon,
    iconMyShopping,
    iconSingOff,
    labelCoupon,
    labelMyShopping,
    labelSingOff,
    showBtnCoupon,
    showBtnMyShopping,
    showBtnSingOff,
    showLabelCoupon,
    showLabelMyShopping,
    showLabelSingOff,
    textLabelCoupon,
    textLabelMyShopping,
    textLabelSingOff
  }
  
  return {
    cfDataHeader
  }
}